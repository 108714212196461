import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { useFetch } from "hooks";
import { put, get } from "utils/api";
import PatientSearchResults from "./patient-search-results";
import MdSearch from "react-icons/lib/md/search";
import { connect, useSelector } from "react-redux";
import { fetchCompanyTable } from "actions/companiesTable";
import { selectors } from "reducers";

const PatientSearchForm = ({
  feature,
  multiple,
  setSelectedPatients,
  selectedPatients,
  fetchCompanyTable,
  companies
}) => {
  const allBranches = useSelector(selectors.getFormOptionsBranch);
  const teams = useSelector(selectors.getTeamNameOptions);
  const sleepCoaches = useSelector(selectors.getFormOptionsSleepCoach).map(
    ({ GUID, name }) => {
      return { text: name, value: GUID };
    }
  );
  const complianceCoaches = useSelector(selectors.getComplianceCoaches);
  const [branches, setBranches] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [coachesDropdown, setCoachesDropdown] = useState([]);

  const {
    response: patients,
    fetchData,
    isFetching
  } = useFetch({
    apiFn: values => put("dme_portal/search_patients", values),
    defaultValue: [],
    transformResponse: ({ patients }) => patients
  });

  const {
    response: companyInsurances,
    fetchData: fetchCompanyInsurances,
    isFetching: isFetchingCompanyInsurances
  } = useFetch({
    apiFn: () => get(`companies/${selectedCompany}/insurances`),
    transformResponse: insurances =>
      insurances
        .filter(x => x.bActive)
        .map(({ sInsuranceCompanyName, sMGInsuranceID }) => ({
          text: sInsuranceCompanyName,
          value: sMGInsuranceID
        })),
    defaultValue: []
  });

  React.useEffect(() => {
    setSelectedPatients(multiple ? [] : "");
  }, [patients]);

  const getCompanies = async () => await fetchCompanyTable(null, null, 0);

  React.useEffect(() => {
    getCompanies();
  }, []);

  const setBranchesByCompany = companyId => {
    setBranches(allBranches.filter(x => x.company == companyId));
  };

  React.useEffect(() => {
    if (selectedCompany) fetchCompanyInsurances();
  }, [selectedCompany]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 10,
        border: "1px solid #eee"
      }}
    >
      <Formik
        initialValues={{
          name: "",
          email: "",
          date_of_birth: "",
          account_number: "",
          company_id: "",
          insurance_id: "",
          stage: null,
          team_id: "",
          coach_id: "",
          branch_id: "",
          from_setup_date: null,
          to_setup_date: null,
          feature
        }}
        onSubmit={async values => {
          await fetchData(values);
        }}
        enableReinitialize={true}
      >
        {({ resetForm, values, setFieldValue }) => (
          <Form className="patient-search-form">
            <div>
              <FormikField name="name" label="Name" type="text" />
              <FormikField name="email" label="Email" type="text" />
              <FormikField
                label="Company"
                name="company_id"
                component="select"
                onClick={async e => {
                  setFieldValue("insurance_id", null);
                  const val = e.target.value;
                  setFieldValue("company_id", val);
                  setBranchesByCompany(val);
                  setSelectedCompany(val);
                }}
              >
                <option />
                {companies.map(({ company_name, id }) => (
                  <option key={id} value={id}>
                    {company_name}
                  </option>
                ))}
              </FormikField>
              <FormikField label="Team" name="team_id" component="select">
                <option />
                {teams.map(({ text, key }) => (
                  <option key={key} value={key}>
                    {text}
                  </option>
                ))}
              </FormikField>
              <FormikField
                name="from_setup_date"
                label="From Setup Date"
                type="date"
              />
              <FormikField label="Coach" name="coach_id" component="select">
                {
                  <option value={null}>
                    {coachesDropdown.length > 0 ? "" : "Select stage..."}
                  </option>
                }
                {coachesDropdown.map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </FormikField>
            </div>
            <div>
              <FormikField
                name="date_of_birth"
                label="Date Of Birth"
                type="date"
              />
              <FormikField
                name="account_number"
                label="Account Number"
                type="text"
              />
              <FormikField
                label="Branch Office"
                name="branch_id"
                component="select"
                disabled={!branches}
              >
                <option />
                {branches &&
                  branches.map(({ value, text }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
              </FormikField>
              <FormikField
                label="Stage"
                name="stage"
                component="select"
                onClick={e => {
                  const stage = e.target.value;
                  if (!stage) {
                    setCoachesDropdown([]);
                    setFieldValue("stage", null);
                  } else {
                    const coachesDrop =
                      stage == 1 ? sleepCoaches : complianceCoaches;
                    setCoachesDropdown(coachesDrop);
                    setFieldValue("stage", stage);
                  }
                }}
              >
                <option value={null}></option>
                <option value={1}>Resupply</option>
                <option value={2}>Compliance</option>
              </FormikField>
              <FormikField
                name="to_setup_date"
                label="To Setup Date"
                type="date"
              />
              <FormikField
                label="Insurance"
                name="insurance_id"
                component="select"
              >
                <option />
                {selectedCompany &&
                  !isFetchingCompanyInsurances &&
                  companyInsurances &&
                  companyInsurances.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
              </FormikField>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <button type="submit">
                <MdSearch />
                Search
              </button>
              <button
                type="button"
                style={{ marginTop: "20px" }}
                onClick={resetForm}
                className="button warning"
              >
                Clear
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <PatientSearchResults
        patients={patients}
        isFetching={isFetching}
        selectedPatients={selectedPatients}
        setSelectedPatients={setSelectedPatients}
        multiple={multiple}
      />
    </div>
  );
};

PatientSearchForm.defaultProps = { feature: "", multiple: true };

PatientSearchForm.propTypes = {
  feature: PropTypes.string,
  setSelectedPatients: PropTypes.func.isRequired,
  selectedPatients: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  multiple: PropTypes.bool,
  companies: PropTypes.array.isRequired,
  fetchCompanyTable: PropTypes.func.isRequired
};

export default connect(
  state => ({
    companies: state.get("companiesTable")
  }),
  {
    fetchCompanyTable
  }
)(PatientSearchForm);
